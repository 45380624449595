.menu-item-index-item{
    margin: 10px;
    position: relative;
    max-width: 125px;
}

.menu-item-index-item img{
    height: 125px;
    width: 125px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    transform: scale(1);
    overflow: hidden;
    position: relative;
}

.menu-item-index-item p{
    margin: 8px 0;
}

.menu-item-name{
    font-size: 12px;
    font-weight: bold;
}

.menu-item-price{
    color: var(--primary-gray);
    font-size: 14px;
}

.menu-item-index-item button{
    position: absolute;
    top: 80px;
    right: 2px;
    max-width: 60px;
    transform: scale(0.8);
    background-color: var(--primary-white);
    box-shadow: 0 0 2px 0 var(--secondary-gray);
    z-index: 10;
    color: var(--primary-black);
}

.menu-item-index-item button:hover{
    background-color: var(--secondary-white);
}