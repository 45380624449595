.cart-main{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--primary-white);
    height: 100%;
    min-width: 300px;
    max-width: 350px;
    z-index: 10;
    color: var(--primary-black);
    font-weight: bold;
    box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.2);
    z-index: 50;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.cart-header{
    margin: 15px 0;
}

.cart-header h3{
    margin: 8px 0;
    cursor: pointer;
}
.cart-header > p{
    color: rgb(118 118 118);
    font-weight: normal;
    font-size: 11px;
}

.checkout-button{
    width: 100%;
    margin-left: 0;
    max-width: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 12px;
}

.cart-list{
    overflow: auto;
}

.cart-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh;
    background-color: transparent;
    z-index: 50; 
}

.cart-close{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    margin: -10px 0px 0px -15px;
    cursor: pointer;
}

.cart-main svg{
    margin-left: 5%;
    color: var(--primary-black);
    fill: var(--primary-black);
}

.cart-header svg:hover{
    cursor: pointer;
}

.cart-header svg{
    transform: scale(0.6);
}

.empty-cart{
    padding-left: 30px;
    transform: scale(1.2);
}

