.restaurant-index-item{
    border-radius: 4px;
    padding: 10px 10px 10px 0px;
    cursor: pointer;
    flex: 0 0 30%
}

.restaurant-index-item h3{
    margin-top: 13px;
    margin-bottom: 5px;
}

.restaurant-index-image-container{
    height: 150px;
    min-width: 150px;
    max-width: 320px;
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.restaurant-index-image-container img{
    max-height: 100%;
    height: auto;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    object-fit: cover;
}

.macro-review-container p{
    display: inline;
}

.review-star-svg{
    margin: -2px 6px 0 2px;
    vertical-align: middle;
    transform: scale(0.9);
}

span :nth-child(1){
    font-weight: bold;
    color: var(--primary-gray);
    font-size: 13px;
}

span :nth-child(3){
    color: var(--secondary-gray);
    font-size: 12px;
}

@media screen and (max-width: 599px){
    .restaurant-index-item{
        flex: 0 0 45%;
    }
}