.orders{
    color: var(--primary-black);
    display: flex;
    justify-content: space-around;
}

.orders-main-container{
    width: 70%;
    margin: 20px 0;
}

@media screen and (max-width: 599px){
    .orders-main-container{
        width: 90%;
    }
}