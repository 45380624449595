.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-black);
    opacity: 0.5;
    z-index: 98;
}

.modal-content{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 100;
    overflow-y: hidden;
}