
.sign-in-form{
    max-width: 450px;
    min-width: 350px;
    background-color: var(--primary-white);
    padding: 25px;
    border-radius: 20px;
    margin-top: 40px;
}

.sign-in-form button{
    cursor: pointer;
    background-color: var(--primary-white);
    color: var(--primary-black);
    width: 12px;
    height: 12px;
    padding: 0px;
    margin: 0px;
}

.sign-in-form form{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.sign-in-form h2{
    color: var(--primary-black);
    margin: 20px 0px;
    font-size: 24px;
}

.sign-in-form p{
    color: var(--primary-black);
    font-size: 14px;
}

.sign-in-form form * {
    padding: 7px;
}

.sign-in-form input[type=submit]{
    margin: 30px auto;
    width: 80%;
    font-weight: bold;
}