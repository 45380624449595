@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input[type=submit]{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/* ------------------------------END_CSS_RESET-------------------------------- */
/* ---------------------------BEGIN_SITEWIDE_STYLES--------------------------- */


:root{
    --primary-red: rgb(235 24 0);
    --secondary-red: rgb(217 18 0);
    --primary-white: rgb(255 255 255);
    --secondary-white: rgb(247, 247, 247);
    --primary-black: rgb(25 25 25);
    --secondary-black: rgb(73 73 73);
    --primary-gray: rgb(73 73 73);
    --secondary-gray: rgb(178, 178, 178);
}

.button, button{
    position: relative;
    width: 78px;
    height: 34px;
    color: var(--primary-white);
    background-color: var(--primary-red);
    text-decoration: none;
    border-radius: 20px;
    font-size: 15px;
    padding: 0px 7px;
    white-space: nowrap;
    margin: 5px;
    font-weight: bold;
    border: none;
}

.button:hover, button:hover{
    background-color: var(--secondary-red);
    cursor: pointer;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--primary-white);
    color: var(--primary-white);
    height: 100%;
    width: 100%;
}

* { 
    font-family: 'Roboto', sans-serif;
}

h1{
    font-size: 30px;
}

h2{
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-black)
}

h3{
    font-size: 15px;
    font-weight: bold;
    color: var(--primary-black)
}

label {
    color: var(--primary-black);
    font-family: 'Roboto', sans-serif;
}

input[type=text], input[type=password]{
    border: none;
    border-radius: 4px;
    background-color: var(--secondary-white);
    height: 25px;
}

a{
    text-decoration: underline;
    color: var(--primary-red)
}

a:hover{
    color: var(--secondary-red);
    cursor: pointer;
}