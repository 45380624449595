.review-index{
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: 25%;
}

@media screen and (max-width: 599px){
    .review-index{
        margin-left: 0;
    }
}