.main-nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    color: var(--primary-white);
    margin: 0 3%;
    z-index: 50;
  }
  
  .main-nav-logo{
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    letter-spacing: 2.5px;
    left: 50%;
    transform: translate(-50%, 0) scaleY(0.95);
    position: absolute;
    font-weight: 500;
  }

  @media screen and (max-width: 599px){
    .main-nav-logo{
      display: none;
    }
  }

  .main-nav-icon{
    position: relative;
    max-width: 42px;
    height: auto;
    margin: 0 auto;
    transform: translate(-38%, 0);
  }

  .main-sign-up-button {
    color: var(--secondary-black);
    background-color: var(--primary-white);
  }

  .main-sign-up-button:hover {
    background-color: var(--primary-white);
  }