.menu-item-show{
    max-width: 75vw;
    min-width: 350px;
    min-height: 50vh;
    background-color: var(--primary-white);
    padding: 25px;
    border-radius: 20px;
    margin-top: 20px;
    z-index: 15;
    position: relative;
    padding-bottom: 70px;
}

.menu-item-show h2{
    font-size: 30px;
    margin: 20px 0;
}

.menu-item-show h3{
    margin: 10px 0;
}

.menu-item-show p{
    color: var(--primary-gray);
    font-size: 14px;
    margin: 12px 0 25px 0;
}

.menu-item-show-main-container{
    overflow-y: auto;
    max-height: 75vh;
    margin-bottom: 15px;
}

.menu-item-show-image-placeholder{
    width: 100%;
    max-height: 250px;
    border-radius: 4px;
    object-fit: cover;
    margin: 0 auto;
}

.menu-item-show-footer{
    width: 80%;
    height: 70px;
    border-top: 0.5px solid var(--secondary-gray);
    background-color: var(--primary-white);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.menu-item-show-footer button{
    width: auto;
    padding: 0 5%;
    margin-right: -30px;
}
