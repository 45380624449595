.review-show{
    min-width: 20vw;
    max-width: 30vw;
    min-height: 45vh;
    overflow-y: auto;
    min-width: 350px;;
    border-radius: 15px;
    background-color: var(--primary-white);
    margin-top: 30%;
    padding: 15px;
}

.review-show h1{
    font-size: 20px;
}

.review-show-stars{
    transform: scale(1);
    margin: 15px 0;
}

.review-show-body{
    line-height: 18px;
}

.review-show-button{
    background-color: rgb(231, 231, 231);
    color: var(--primary-black)
}

.review-show-button:hover{
    background-color: var(--secondary-gray);
}