.review-form{
    max-width: 450px;
    min-width: 300px;
    width: 45vw;
    min-height: 60vh;
    background-color: var(--primary-white);
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;
    z-index: 15;
    position: relative;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.close-icon:hover{
    cursor: pointer;
}

.review-form h2{
    margin: 10px 0 20px 0;
    font-size: 28px;
}

.review-form h3, .min-chars{
    font-weight: normal;
    color: var(--primary-gray);
    font-size: 14px;
    margin: 20px 0;
}

.review-form-header{
    display: flex;
    justify-content: space-between;
    margin: 25px 15px 0 15px;
}

.form-user{
    font-weight: bold;
    font-size: 14px;
}

.public-review-tag{
    background-color: rgb(231, 231, 231) !important;
    padding: 7px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: -10px;
    font-weight: bold;
}

.review-form form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--secondary-white);
    border-radius: 10px;
    min-height: 200px;
}

.review-form form *{
    background-color: transparent;
}

.min-chars{
    font-size: 13px;
}

.review-body{
    border: none;
    min-height: 80px;
    resize: none;
    padding: 10px;

}

.review-form-footer{
    width: 80%;
    height: 70px;
    border-top: 0.5px solid var(--secondary-gray);
    background-color: var(--primary-white);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10%;
    position: absolute;
    bottom: 0;
    left: 0;
}