
.sign-up-form{
    max-width: 450px;
    min-width: 350px;
    max-height: 85vh;
    overflow-y: auto;
    background-color: var(--primary-white);
    padding: 25px;
    border-radius: 20px;
    margin-top: 20px;
}

.sign-up-form button{
    cursor: pointer;
    background-color: var(--primary-white);
    color: var(--primary-black);
    width: 12px;
    height: 12px;
    padding: 0px;
    margin: 0px;
}

.sign-up-form form{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.sign-up-form h2{
    color: var(--primary-black);
    font-size: 24px;
    margin: 20px 0px;
}

.sign-up-form p{
    color: var(--primary-black);
    font-size: 14px;
    margin-top: 20px;
}

.sign-up-form form * {
    padding: 7px;
}

.sign-up-form input[type=submit]{
    margin: 10px auto;
    width: 80%;
    font-weight: bold;
}