.checkout{
    color: var(--primary-black);
}

.checkout h3{
    font-size: 18px;
}

.checkout-account-details{
    width: 50%;
    min-height: 25px;
    padding: 20px;
    margin: 50px 0 20px 50px;
    border: 1px solid rgb(231 231 231);
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-account-details p{
    color: var(--primary-gray);
    letter-spacing: 1px;
}

.checkout-shipping-details{
    width: 50%;
    height: 200px;
    border: 1px solid rgb(231 231 231);
    border-radius: 15px;
    padding: 20px;
    margin: 0 50px;
}

.checkout-payment-details{
    margin-top: 20px;
    height: 60px;
}

.checkout-user-info *{
    margin: 15px;
    font-size: 14px;
    margin-left: 25px;
}

.checkout-user-info h3{
    font-size: 14px;
    margin-bottom: -5px;
}

.checkout-cart-details{
    position: absolute;
    right: 0;
    top: 70px;
    width: 35%;
    height: 100%;
    border-left: 1px solid var(--secondary-gray)
}

.checkout-cart{
    width: 95%;
    margin: 10px auto;
}

.checkout-cart-details{
    color: var(--primary-black)
}

.checkout-button{
    margin-top: 15px;
}

@media screen and (max-width: 649px){
    .checkout{
        display: flex;
        flex-direction: column;
    }

    .checkout-cart-details{
        position: relative;
        border: 1px solid rgb(231 231 231);
        border-radius: 15px;
        min-height: 200px;
    }
    
    .checkout-account-details, .checkout-shipping-details, .checkout-payment-details, .checkout-cart-details{
        width: 90%;
        margin: 5px 15px;
        padding: 10px;
    }

    .checkout-cart-details{
        margin-top: -50px;
    }
}
