.order-index-item{
    width: 100%;
    height: 70px;
    border-radius: 8px;
    margin: 10px 0;
    border: solid 1px rgb(231 231 231);
}

.order-index-item-header{
    background-color: var(--secondary-white);
    width: 98%;
    padding: 1%;
    height: 25%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

#arrow-svg{
    transform: scale(0.8);
}

.order-index-item-body{
    width: 98%;
    height: 50%;
    padding: 0 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--primary-gray);
}