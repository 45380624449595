.error-banner{
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 325px);
    min-height: 50px;
    opacity: 0.95;
    background-color: var(--primary-red);
    color: white;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 10;
}

.slide-out-animation{
    animation: slide-out;
    animation-duration: 5s;
    display: flex;
}

.full-width{
    width: 100vw;
}

@keyframes slide-out{
    0%{
        transform: translate(0, 65px);
    }
    50%{
        transform: translate(0%, 65px);
    }
    100%{
        transform: translate(0%, -65px);
    }
}

@media screen and (max-width: 1000px){
    .error-banner{
        width: calc(100vw - 375px);
        box-sizing: content-box;
        padding: 10px 40px;
    }
    .full-width{
        width: 100vw;
    }
}

@media screen and (max-width: 749px){
    .error-banner{
        top: -65px;
        width: 90vw;
        z-index: 1000;
    }
}