.restaurant-index-container{
    margin: 20px auto;
    width: 80%;
    height: 100%;
}

@media screen and (max-width: 649px) {
    .restaurant-index-container{
        width: 100%;
        margin: 20px auto;
        padding: 0 10px;
        overflow-x: auto;
    }
}

.restaurant-index ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.restaurant-index h2{
    margin-left: 20px;
}

